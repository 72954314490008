button.btn[data-v-0a2a5063] {
    display: inline-block;
    font-weight: 300;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    cursor: pointer;
    letter-spacing: 1px;
    transition: all .15s ease
}

button.btn.btn-sm[data-v-0a2a5063] {
    padding: .4rem .8rem;
    font-size: .8rem;
    border-radius: .2rem
}

button.btn.btn-primary[data-v-0a2a5063] {
    color: #fff;
    background-color: #45C8F1;
    border-color: #45C8F1
}

button.btn.btn-outline-primary[data-v-0a2a5063] {
    color: #45C8F1;
    background-color: transparent;
    border-color: #45C8F1
}

button.btn.btn-danger[data-v-0a2a5063] {
    color: #fff;
    background-color: #FF4949;
    border-color: #FF4949
}

.text-muted[data-v-0a2a5063] {
    color: #8492A6
}

.text-center[data-v-0a2a5063] {
    text-align: center
}

.drop-down-enter[data-v-0a2a5063],
.drop-down-leave-to[data-v-0a2a5063] {
    transform: translateX(0) translateY(-20px);
    transition-timing-function: cubic-bezier(0.74, 0.04, 0.26, 1.05);
    opacity: 0
}

.drop-down-enter-active[data-v-0a2a5063],
.drop-down-leave-active[data-v-0a2a5063] {
    transition: all .15s
}

.move-left-enter[data-v-0a2a5063],
.move-left-leave-to[data-v-0a2a5063] {
    transform: translateY(0) translateX(-80px);
    transition-timing-function: cubic-bezier(0.74, 0.04, 0.26, 1.05);
    opacity: 0
}

.move-left-enter-active[data-v-0a2a5063],
.move-left-leave-active[data-v-0a2a5063] {
    transition: all .15s
}

.shake[data-v-0a2a5063] {
    animation: shake-data-v-0a2a5063 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0)
}

@keyframes shake-data-v-0a2a5063 {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0)
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0)
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0)
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0)
    }
}

.pulse[data-v-0a2a5063] {
    animation: pulse-data-v-0a2a5063 2s ease infinite
}

@keyframes pulse-data-v-0a2a5063 {
    0% {
        opacity: .7
    }

    50% {
        opacity: .4
    }

    100% {
        opacity: .7
    }
}

.flash-once[data-v-0a2a5063] {
    animation: flash-once 3.5s ease 1
}

@keyframes fade-up-data-v-0a2a5063 {
    0% {
        transform: translate3d(0, 10px, 0);
        opacity: 0
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.fade-in[data-v-0a2a5063] {
    animation: fade-in-data-v-0a2a5063 .3s ease-in-out
}

@keyframes fade-in-data-v-0a2a5063 {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.spin[data-v-0a2a5063] {
    animation-name: spin-data-v-0a2a5063;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

@keyframes spin-data-v-0a2a5063 {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.bounceIn[data-v-0a2a5063] {
    animation-name: bounceIn-data-v-0a2a5063;
    transform-origin: center bottom;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1
}

@keyframes bounceIn-data-v-0a2a5063 {

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    }

    0% {
        opacity: 1;
        -webkit-transform: scale3d(0.8, 0.8, 0.8);
        transform: scale3d(0.8, 0.8, 0.8)
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03)
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes dots-data-v-0a2a5063 {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0)
    }

    40% {
        color: #8492A6;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0)
    }

    60% {
        text-shadow: 0.25em 0 0 #8492A6, 0.5em 0 0 rgba(0, 0, 0, 0)
    }

    80%,
    100% {
        text-shadow: .25em 0 0 #8492A6, .5em 0 0 #8492A6
    }
}

@keyframes recording-data-v-0a2a5063 {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3)
    }

    65% {
        box-shadow: 0px 0px 5px 5px rgba(173, 0, 0, 0.3)
    }

    90% {
        box-shadow: 0px 0px 5px 5px rgba(173, 0, 0, 0)
    }
}

html[data-v-0a2a5063] {
    max-height: 500px
}

body[data-v-0a2a5063] {
    margin: 0;
    font-size: 100%;
    color: #3C4858;
    width: 350px;
    max-height: 500px
}

a[data-v-0a2a5063] {
    text-decoration: none;
    color: #45C8F1
}

h1[data-v-0a2a5063],
h2[data-v-0a2a5063],
h3[data-v-0a2a5063],
h4[data-v-0a2a5063] {
    margin-top: 0
}

.container_selected_area[data-v-0a2a5063] {
    padding: 0px;
    position: fixed;
    z-index: 2147483647;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: none;
    visibility: hidden
}

.container_selected_area.active[data-v-0a2a5063] {
    visibility: visible;
    display: block
}

.container_selected_area .label[data-v-0a2a5063] {
    font-family: "Didact Gothic Regular", sans-serif;
    font-size: 22px;
    text-align: center;
    padding-top: 15px
}

.container_selected_area .area[data-v-0a2a5063] {
    border: 1px solid #1e83ff;
    background: rgba(30, 131, 255, 0.1);
    display: none;
    position: fixed;
    z-index: 2147483647;
    box-sizing: border-box
}

.container_selected_area .area.active[data-v-0a2a5063] {
    display: block;
    top: 0px;
    left: 0px
}

.hide[data-v-0a2a5063] {
    display: none
}

