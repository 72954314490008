
.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #03034b
    }
    
    .btn-primary {
    background-color: #2a9df4
    }
    
    .btn-secondary {
    background-color: #03034b
    
    }
    
    .input-group-text{
    background:transparent;
    }
    
    .user-header1 {
    height: 120px;
    text-align: center;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    background-color: #03034b;
    text-color: #fff;
    }
    
    .navbar.user.dropdown-toggle:after{
    display: none;
    }
    
    .info-box {
    padding: 28px 15px;
    }
    .info-box1{
        box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
        border-radius: .25rem;
        background-color: #fff;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 1rem;
        min-height: 80px;
        padding: .5rem;
        position: relative;
        width: 100%;
    }
    .info-box1 .info-box-icon {
        border-radius: .25rem;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        font-size: 1.875rem;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        width: 70px;
    }
    .info-box1 .info-box-content {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 1.8;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding: 0 10px;
    }
    .brand-link {
    display: block;
        font-size: 1.25rem;
        line-height: 1.5;
        padding: 0.60rem .5rem;
        transition: width .3s ease-in-out;
        white-space: nowrap;	
    
    }
    .card-body-balance{	
        padding-bottom: 126px!important;
        margin-left: .5rem!important;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
        text-align: left;
    }
    

    .react-datepicker-wrapper {
    width: calc(100% - 37px) !important;
}